import {addQueryParams, stringifyParams} from '@wix/panda-js-utils'
import {
  EVENT_FILTER_TYPE,
  EVENT_SORT_ORDER,
  EventsHttpClient,
  LIST_LAYOUT,
  RECURRING_FILTER,
  WIDGET_TYPE,
  WidgetData,
} from '@wix/wix-events-commons-statics'

export interface CommonWidgetDataParams {
  language: string
  locale: string
  tz: string
  viewMode: string
  members: boolean
  paidPlans: boolean
  responsive: boolean
  membersV2?: boolean
  newClassicEvents?: boolean
}

export interface GetWidgetDataParams extends CommonWidgetDataParams {
  compId: string
  showcase: boolean
  widgetType?: WIDGET_TYPE
  listLayout?: LIST_LAYOUT
  recurringFilter?: RECURRING_FILTER
  filterType?: EVENT_FILTER_TYPE
  sortOrder?: EVENT_SORT_ORDER
  categoryId?: string
  noCategoryIdFallback?: boolean
  limit?: number
}

export const getWidgetData =
  (api: EventsHttpClient, logError: (error: Error) => never) =>
  async ({
    responsive = false,
    locale,
    language,
    compId,
    viewMode,
    members,
    paidPlans,
    widgetType,
    listLayout,
    recurringFilter,
    showcase = false,
    tz,
    filterType,
    sortOrder,
    categoryId,
    noCategoryIdFallback,
    limit,
    membersV2,
    newClassicEvents,
  }: GetWidgetDataParams): Promise<WidgetData> => {
    return api
      .get<WidgetData>(
        addQueryParams(
          `/html/v2/widget-data`,
          stringifyParams({
            compId,
            locale: language,
            regional: locale,
            viewMode,
            members,
            paidPlans,
            responsive,
            widgetType,
            listLayout,
            recurringFilter,
            showcase,
            tz,
            filterType,
            sortOrder,
            categoryId,
            noCategoryIdFallback,
            limit,
            membersV2,
            newClassicEvents,
          }),
        ),
      )
      .catch(logError)
  }

export interface GetFeaturedWidgetDataParams extends CommonWidgetDataParams {
  eventId: string
  categoryId: string
  manuallySelected: boolean
}

export const getFeaturedWidgetData =
  (api: EventsHttpClient, logError: (error: Error) => never) =>
  async ({responsive = false, locale, language, ...rest}: GetFeaturedWidgetDataParams): Promise<WidgetData> => {
    return api
      .get(
        addQueryParams(
          '/html/featured-widget-data',
          stringifyParams({
            responsive,
            locale: language,
            regional: locale,
            ...rest,
          }),
        ),
      )
      .catch(logError) as Promise<WidgetData>
  }
