import {StatusFilter} from '@wix/events-types'
import {addQueryParams, stringifyParams} from '@wix/panda-js-utils'
import {
  CalendarDates,
  EVENT_FILTER_TYPE,
  EVENT_SORT_ORDER,
  EventsDates,
  RECURRING_FILTER,
} from '@wix/wix-events-commons-statics'
import {ControllerParams, IUser} from '@wix/yoshi-flow-editor'
import {UPDATE_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../../../commons/types/state'
import {Api as BaseApi} from '../../../../commons/utils/api'
import {getFeaturedWidgetData, getWidgetData} from '../../shared-api'
import {LOAD_MEMBERS_FOR_EVENTS, PROMPT_LOGIN} from '../actions/members'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [LOAD_MEMBERS_FOR_EVENTS.NAME]: this.getGuestLists,
    }
  }

  getWidgetData = getWidgetData(this.api, this.logError)

  getFeaturedWidgetData = getFeaturedWidgetData(this.api, this.logError)

  loadEvents = (args: LoadEventsParams): Promise<LoadEventsResponse> => {
    return this.api
      .get(
        addQueryParams(
          '/web/paginated-events/viewer',
          stringifyParams({
            ...args,
            draft: this.viewMode !== 'site',
            compId: this.compId,
          }),
        ),
      )
      .catch(this.logError) as Promise<LoadEventsResponse>
  }

  loadFeaturedEvent = (args: LoadFeaturedEventParams): Promise<LoadFeaturedEventResponse> => {
    return this.api
      .get(addQueryParams('/web/featured-event/viewer', stringifyParams(args)))
      .catch(this.logError) as Promise<LoadFeaturedEventResponse>
  }

  loadCalendarEvents = (args: LoadCalendarEventsParams): Promise<LoadCalendarEventsResponse> => {
    return this.api
      .get(
        addQueryParams(
          '/web/calendar-events/viewer',
          stringifyParams({
            ...args,
            draft: this.viewMode !== 'site',
            compId: this.compId,
            tz: this.tz,
          }),
        ),
      )
      .catch(this.logError) as Promise<LoadCalendarEventsResponse>
  }

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings}).catch(this.logError)
  }

  getGuestLists = (eventIds: string[]): Promise<GuestLists> => {
    return this.api.get(`/web/members?eventIds=${JSON.stringify(eventIds)}`).catch(this.logError) as Promise<GuestLists>
  }

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        const resolvedError = this.errorHandler.getResolvedError(e)
        console.error(resolvedError)
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  onLogin = (handler: LoginHandler) => {
    this.controller.wixCodeApi.user.onLogin(handler)
  }
}

export type LoginHandler = (user: IUser) => void

interface LoadEventsParams {
  offset: number
  filter: StatusFilter
  byEventId: boolean
  members: boolean
  paidPlans: boolean
  locale: string
  categoryId: string[]
  recurringFilter?: RECURRING_FILTER
  filterType: EVENT_FILTER_TYPE
  sortOrder: EVENT_SORT_ORDER
  limit?: number
}

export interface LoadEventsResponse {
  events: ExtendedEvent[]
  dates: {events: EventsDates}
  hasMore: boolean
  total: number
}

export interface LoadFeaturedEventParams {
  members: boolean
  paidPlans: boolean
  locale: string
  categoryId: string
  eventId: string
  manuallySelected: boolean
}

export interface LoadFeaturedEventResponse {
  featuredEvent: ExtendedEvent
  dates: {events: EventsDates}
}

interface LoadCalendarEventsParams {
  referenceDate: string
  filter: StatusFilter
  byEventId: boolean
  members: boolean
  paidPlans: boolean
  locale: string
  categoryId: string[]
  showcase: boolean
  filterType: EVENT_FILTER_TYPE
  sortOrder: EVENT_SORT_ORDER
  multiDayExperimentEnabled?: boolean
  expandBounds?: boolean
}

export interface LoadCalendarEventsResponse {
  events: ExtendedEvent[]
  dates: {events: EventsDates; calendar: CalendarDates}
}
